<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { handleLoginCallback } from "@two-ui/auth0";
import { useRouter } from "vue-router";
import FullPageLoader from "@two-ui/components/FullPageLoader.vue";
import { doLogout } from "@two-ui/auth0";

export default defineComponent({
  components: {
    FullPageLoader,
  },
  setup() {
    const router = useRouter();
    onMounted(async () => {
      try {
        await handleLoginCallback();
      } catch (e) {
        console.error("Error while handling auth callback:", e);
        doLogout();
        return;
      }
      router.push({ name: "api-keys-create", params: { type: "production" } });
    });
  },
});
</script>

<template>
  <FullPageLoader>Just logging you in…</FullPageLoader>
</template>
